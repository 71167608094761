$navyBlue100: #1f4d93;
$darkBlue100: #0a1331;
$ocean100: #005a76;
$blue: #0085ff;
$gunPowder: #1f1f20;
$blueTrim: #d7eef3;
$softGray: #eef6fa;
$softBlue: #f6fafc;

$inert: #cccccc;
$neutral: #ececec;
$textfieldGray: #f4f4f4;
$ceramic: #f8f8f8;
$white: #ffffff;
$black: #000000;
$badgeBlue: #e0ecf7;
$badgeText: #2d3748;
$redPrompt: #bc2f2f;
$redAlert: #ea746c;
$redAccent: #f3d9d9;

$greenPrompt: #397c28;
$greenAlert: #5fa24f;
$greenAccent: #cef2cb;
$green: #127701;
$fuchosia-400: #972046;

$maybeYellow: #f6db94;

$boxShadow: #00000040;
$boxBackground: #f2f5f7;

$colorsbackground: rgba(19, 22, 25, 1);
$shadow-heavy: 0px 16px 32px 0px rgba(0, 0, 0, 0.2);

$gray-700: #4f5868;
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@100;200;300;400;500;600;700;800;900&display=swap");

@function a($x) {
  $base: 8;
  @return ($base * $x) + px;
}

@mixin roundBorder {
  border-radius: a(0.75);
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideInMenu {
  from {
    transform: translateX(100%);
    //opacity: 0;
  }
  to {
    transform: translateX(0%);
    //opacity: 1;
  }
}

@keyframes slideOutMenu {
  from {
    transform: translateX(0%);
    //opacity: 1;
  }
  to {
    transform: translateX(100%);
    //opacity: 0;
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}

$font: "Lato", "Source Sans Pro", "Segoe UI", "Roboto", sans-serif;
//$font: "Source Sans Pro", sans-serif;
