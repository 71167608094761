@font-face {
  font-family: "Source Sans Pro";
  src: url("SourceSansPro-BlackIt.woff2") format("woff2"),
    url("SourceSansPro-BlackIt.woff") format("woff");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Source Sans Pro";
  src: url("SourceSansPro-Bold.woff2") format("woff2"),
    url("SourceSansPro-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Source Sans Pro";
  src: url("SourceSansPro-BoldIt.woff2") format("woff2"),
    url("SourceSansPro-BoldIt.woff") format("woff");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Source Sans Pro";
  src: url("SourceSansPro-Black.woff2") format("woff2"),
    url("SourceSansPro-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Source Sans Pro";
  src: url("SourceSansPro-It.woff2") format("woff2"),
    url("SourceSansPro-It.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Source Sans Pro";
  src: url("SourceSansPro-ExtraLightIt.woff2") format("woff2"),
    url("SourceSansPro-ExtraLightIt.woff") format("woff");
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Source Sans Pro";
  src: url("SourceSansPro-LightIt.woff2") format("woff2"),
    url("SourceSansPro-LightIt.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Source Sans Pro";
  src: url("SourceSansPro-ExtraLight.woff2") format("woff2"),
    url("SourceSansPro-ExtraLight.woff") format("woff");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Source Sans Pro";
  src: url("SourceSansPro-Light.woff2") format("woff2"),
    url("SourceSansPro-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Source Sans Pro";
  src: url("SourceSansPro-Regular.woff2") format("woff2"),
    url("SourceSansPro-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Source Sans Pro";
  src: url("SourceSansPro-SemiboldIt.woff2") format("woff2"),
    url("SourceSansPro-SemiboldIt.woff") format("woff");
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Source Sans Pro";
  src: url("SourceSansPro-Semibold.woff2") format("woff2"),
    url("SourceSansPro-Semibold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
