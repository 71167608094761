.RaLayout-appFrame {
  margin-top: 0 !important;
  & > header {
    position: relative;
  }
}

.RaSidebar-fixed {
  height: auto !important;
}
