.timeline {
  display: flex;
  width: 100%;
  margin-top: 20px;
}

.timeline .container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 0;
  overflow-x: auto;
  padding-bottom: 20px;

  & .lines {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-left: 55px;
    margin-top: 0;
    user-select: none;

    & .dot {
      width: 14px;
      height: 14px;
      background: #d1d6e6;
      border-radius: 7px;
    }

    & .line {
      width: 130px;
      height: 2px;
      background: #d1d6e6;
      margin-top: 5.3px;
    }

    & .checked {
      background: #000000;
    }
  }
  & .cards {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
    margin-top: 15px;
    user-select: none;

    & .card {
      display: grid;
      align-items: center;
      justify-items: center;
      background: #ffffff;
      box-shadow: 0 2px 2px 0 #eeeeee40;
      border-radius: 10px;
      box-shadow: 0px 16px 15px -10px rgba(105, 96, 215, 0.0944602);

      width: 100px;
      height: 73px;
      padding: 0 15px;

      & > p {
        font-family: Open Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 22px;
        color: #2b2862;
      }
    }
  }
  .container::-webkit-scrollbar {
    width: 16px;
  }

  .container::-webkit-scrollbar-track {
    background-color: #e4e4e4;
    border-radius: 100px;
  }

  .container::-webkit-scrollbar-thumb {
    background-color: #d4aa70;
    border-radius: 100px;
  }
}

@media screen and (max-width: 992px) {
  .timeline {
    margin-top: 69.5px;
  }
  .timeline .container {
    width: unset !important;
    max-width: 100%;
    margin: 0 auto;
    flex-direction: row;
    overflow: unset;

    & .lines {
      flex-direction: column;
      align-items: flex-start;
      margin-left: 40px;
      margin-top: 6px;

      & .line {
        height: 103px;
        width: 2px;
        margin-left: 5.3px;
        margin-top: 0;
      }
    }
    & .cards {
      margin-left: 12px;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 46px;
      margin-top: 15px;
      transform: translateY(-50px);

      & .card:first-of-type {
        margin-bottom: -15px;
      }
      & .card:last-of-type {
        margin-top: -15px;
      }

      & .card {
        width: 199px;
        height: 93px;
        padding: 0 15px;
        justify-items: unset;
      }

      & .card.mid {
        height: 71px;
      }
      & .card:not(.mid) {
        height: 97px;
        color: red;
      }
    }
  }
}
