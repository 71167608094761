.profile-icon {
  border-radius: 1000px;
  height: 48px;
  position: relative;
  width: 48px;
  margin-right: 20px;
}

.profile-icon .initials {
  color: #000000;
  font-family: var(--lato-semi-bold-XL-font-family);
  font-size: var(--lato-semi-bold-XL-font-size);
  font-style: var(--lato-semi-bold-XL-font-style);
  font-weight: var(--lato-semi-bold-XL-font-weight);
  height: 47px;
  left: 0;
  letter-spacing: var(--lato-semi-bold-XL-letter-spacing);
  line-height: var(--lato-semi-bold-XL-line-height);
  position: absolute;
  text-align: center;
  top: -1px;
  width: 48px;
  text-align: center;
  display: table;
  height: 100%;
  & > p {
    display: table-cell;
    vertical-align: middle;
  }
}

.profile-icon .initials.menu {
  left: 0;
  top: -1px;
}

.profile-icon .overlap-group {
  border-radius: 29px;
  height: 58px;
  left: -5px;
  position: absolute;
  top: -5px;
  width: 58px;
}

.profile-icon .initials.popup {
  left: 5px;
}

.profile-icon .ellipse {
  border: 2px solid;
  border-color: var(--coworking-gold350);
  border-radius: 29px;
  height: 54px;
  left: 0;
  position: absolute;
  top: 0;
  width: 54px;
}

.profile-icon .profile-icon-variant {
  align-items: flex-end;
  background-color: #ffffff;
  border-radius: 16px;
  box-shadow: var(--shadow-medium);
  display: inline-flex;
  flex-direction: column;
  gap: 8px;
  left: -152px;
  padding: 24px 20px 16px;
  position: absolute;
  top: 87px;
}

.profile-icon .frame {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 12px;
  position: relative;
  width: 100%;
}

.profile-icon .initials-wrapper {
  background-color: #e4b91a;
  border-radius: 1000px;
  height: 48px;
  position: relative;
  width: 48px;
}

.profile-icon .frame-2 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 4px;
  position: relative;
}

.profile-icon .text-wrapper {
  letter-spacing: 0;
  position: relative;
  text-align: center;
  width: 160px;
}

.profile-icon .text-name {
  color: #25272b;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 700;
  line-height: normal;
  margin-top: -1px;
}

.profile-icon .text-mail {
  color: var(--gray-350);
  font-family: "Lato", Helvetica;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
}

.profile-icon .divider {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 8px;
  padding: 4px 0px;
  position: relative;
  width: 100%;
}

.profile-icon .img {
  align-self: stretch;
  height: 1px;
  margin-top: -1px;
  object-fit: cover;
  position: relative;
  width: 100%;
}

.profile-icon .button {
  all: unset;
  align-items: center;
  align-self: stretch;
  background-color: #ffffff;
  box-sizing: border-box;
  display: flex;
  flex: 0 0 auto;
  gap: 8px;
  justify-content: center;
  padding: 7.5px 16px;
  position: relative;
  width: 100%;
}

.profile-icon .placeholder-wrapper {
  all: unset;
  cursor: pointer;
  align-items: center;
  align-self: stretch;
  box-sizing: border-box;
  display: flex;
  flex: 0 0 auto;
  gap: 8px;
  justify-content: center;
  padding: 7.5px 16px;
  position: relative;
  width: 100%;
}

.profile-icon .links {
  color: var(--gray-600);
  font-family: var(--semi-bold-s-font-family);
  font-size: var(--semi-bold-s-font-size);
  font-style: var(--semi-bold-s-font-style);
  font-weight: var(--semi-bold-s-font-weight);
  letter-spacing: var(--semi-bold-s-letter-spacing);
  line-height: var(--semi-bold-s-line-height);
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.profile-icon .sign-out {
  color: #db3b21;
}

.profile-icon.property-default {
  background-color: #e4b91a;
}

.profile-icon.property-variant-3 {
  background-color: #e4b91a;
}

.profile-icon.property-h-OVER {
  background-color: #00000014;
}
