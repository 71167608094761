@import "../../../styles/colors";

.home-community-box {
  flex: 1;
  position: relative;
  display: flex;
}

.home-community-box .section-background {
  background-size: 100% 100%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding-bottom: 2px;
}

.home-community-box .rectangle {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.home-community-box .fl-community-desc {
  height: 431px;
  margin: 0 auto;
  position: relative;
  margin-top: 155px;
  max-width: 608px;
}

.home-community-box .learn-more-button {
  align-items: center;
  background-color: $badgeText;
  display: inline-flex;
  gap: 16px;
  justify-content: center;
  padding: 15px 32px;
  margin-top: 80px;
}

.home-community-box .learn-more-button .icon {
  height: 16px;
  color: $white;
  width: 16px;
}

.home-community-box .text-wrapper {
  color: $white;
  font-family: "Roboto", Helvetica;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.08px;
  line-height: 17.9px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.home-community-box .div {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 24px;
}

.home-community-box .future-labs-network {
  color: $white;
  font-family: "Bio Sans-ExtraBold", Helvetica;
  font-size: 62px;
  font-weight: 400;
  letter-spacing: 6.82px;
  line-height: 74.4px;
  margin-top: -0.54px;
  position: relative;
  transform: rotate(-0.12deg);
  width: fit-content;
}

.home-community-box .span {
  font-weight: 800;
}

.home-community-box .text-wrapper-2 {
  font-family: "Bio Sans-Regular", Helvetica;
}

.home-community-box .frame {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  position: relative;
  width: 208px;
}

.home-community-box .ellipse {
  background-color: $white;
  border-radius: 4px;
  height: 8px;
  position: relative;
  width: 8px;
}

.home-community-box .img {
  flex: 1;
  flex-grow: 1;
  height: 1px;
  position: relative;
}

.home-community-box .nasza-platforma {
  color: $white;
  font-family: var(--lato-regular-XL-font-family);
  font-size: var(--lato-regular-XL-font-size);
  font-style: var(--lato-regular-XL-font-style);
  font-weight: var(--lato-regular-XL-font-weight);
  letter-spacing: var(--lato-regular-XL-letter-spacing);
  line-height: var(--lato-regular-XL-line-height);
  position: relative;
  transform: rotate(-0.12deg);
  width: auto;
}

.home-community-box .community-container {
  position: relative;
  padding: 72px 24px;
}

.home-community-box .community-container .title {
  color: $white;
  font-family: "Lato", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 24px;
  width: 290px;
}

.home-community-box .community-blocks {
  margin-top: 64px;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 316px;
}

.home-community-box .community-block {
  height: 109px;
  position: relative;
  width: 292px;
}

.home-community-box .title-frame {
  align-items: center;
  display: flex;
  left: 0;
  position: absolute;
  top: 0;
  width: 292px;
}

.home-community-box .text-wrapper-3 {
  color: $white;
  flex: 1;
  font-family: var(--lato-bold-l-font-family);
  font-size: var(--lato-bold-l-font-size);
  font-style: var(--lato-bold-l-font-style);
  font-weight: var(--lato-bold-l-font-weight);
  letter-spacing: var(--lato-bold-l-letter-spacing);
  line-height: var(--lato-bold-l-line-height);
  margin-top: -1px;
  position: relative;
}

.home-community-box .vector-2 {
  height: 12px;
  position: relative;
  width: 6px;
}

.home-community-box .incon-container {
  align-items: flex-start;
  display: flex;
  gap: 16px;
  left: 0;
  position: absolute;
  top: 51px;
  width: 292px;
}

.home-community-box .ellipse-2 {
  height: 58px;
  position: relative;
  width: 58px;
}

@media only screen and (max-width: 600px) {
  .home-community-box {
    height: auto;
    flex-wrap: wrap;
  }
  .community-container {
    margin: 0 auto;
  }
  .fl-community-desc {
    padding: 72px 24px 0 24px;
    margin: 50px auto !important;
    width: 300px !important;
    height: auto !important;
  }
  .div {
    gap: 15px;
  }
  .future-labs-network {
    font-size: 40px !important;
    line-height: 50px !important;
  }

  .learn-more-button {
    margin: 40px 0 !important;
  }
}
