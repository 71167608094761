@import "../../../styles/colors";

.project-page {
  margin: 0 auto;
  max-width: 1000px;
  //align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 24px;
  padding-bottom: 80px;
  position: relative;
  min-width: 350px;

  .heading {
    align-items: flex-start;
    align-self: stretch;
    background-color: $white;
    box-shadow: 0px 1px 8px $boxShadow;
    display: flex;
    flex: 0 0 auto;
    gap: 24px;
    padding: 24px;
    position: relative;
    flex-wrap: wrap;

    h2 {
      position: relative;
      white-space: nowrap;
      width: fit-content;
    }

    & > .rectangle {
      width: 100%;
      height: auto;
      max-width: 410px;
      min-height: 200px;
      position: relative;
      object-fit: scale-down;

      background-color: $black;
    }

    & > .container {
      align-items: flex-start;
      display: flex;
      flex: 1;
      flex-direction: column;
      flex-grow: 1;
      gap: 16px;
      position: relative;
    }

    & .cta {
      align-items: flex-start;
      background-color: $badgeBlue;
      display: flex;
      flex: 1;
      flex-grow: 1;
      flex-grow: 1;
      gap: 16px;
      padding: 16px;
    }
  }
  .description {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    padding: 0px 24px;
    position: relative;
  }

  .container {
    align-self: stretch;
    position: relative;
    width: 100%;

    .shadow-box {
      backdrop-filter: blur(40px) brightness(100%);
      background-color: $boxBackground;
      border-radius: 16px;
      box-shadow: 0px 4px 4px $boxShadow;
    }
    .box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
    }
    & .text-container {
      display: flex;
      flex-direction: column;
      gap: 24px;
      padding: 5% 5%;
    }

    & .rectangle {
      padding: 5% 5%;
    }
  }

  .frame {
    align-items: center;
    display: flex;
    flex: 0 0 auto;
    position: relative;
    width: 208px;

    & .ellipse {
      background-color: $black;
      border-radius: 4px;
      height: 8px;
      position: relative;
      width: 8px;
    }
  }
}

aside.project-members {
  width: 498px;
  height: calc(100% - 48px);
  margin-right: auto;
  position: absolute;
  top: 0;
  right: 0;
  align-items: flex-start;
  background-color: $white;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
  min-width: 350px;

  .person-cointainer {
    display: flex;
    flex-direction: column;
    gap: 24px;
    overflow-y: auto;
    width: 100%;
  }
}

.aside-left {
  margin-right: calc((100% - 498px) / 2);
}

@media all AND (max-width: 2100px) {
  .aside-left {
    margin-right: calc((100% - 498px) / 2);
  }
}

@media all AND (max-width: 1550px) {
  aside.project-members {
    width: 350px;
  }
}

@media all AND (max-width: 1300px) {
  aside.project-members {
    max-width: 498px;
    width: unset;
    min-height: 100%;
    margin-right: auto;
    position: unset;
    top: unset;
    right: unset;
    gap: 24px;
    padding: 40px 16px;
    margin: 0 auto;
  }
  .aside-left {
    margin-right: auto;
  }
}
