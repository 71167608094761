#vacats {
  scroll-margin: 120px;
}

.vacant-instance {
  flex-wrap: wrap;
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16px;
  min-height: 200px;
  margin: 20px 0;
}

.vacant-item {
  flex: 0 0 auto;
  flex-wrap: wrap;
  align-items: center;
  background-color: #ffffff;
  display: flex;
  gap: 24px;
  padding: 24px;
  position: relative;
  box-shadow: 0px 1px 8px #0000001f;
}

.vacant-item .container {
  align-items: flex-start;
  display: flex;
  flex: 1 0 0;
  flex-direction: column;
  gap: 4px;
  position: relative;

  & .line {
    align-items: center;
    //align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    gap: 8px;
    position: relative;
    width: 100%;
    justify-content: flex-start;
  }

  & .title {
    color: #363b42;
    font-family: "Bio Sans-Bold", Helvetica;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 24px;
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }

  & .desc {
    max-width: 100%;
    max-height: 3em;
    & p {
      display: -webkit-box;
      max-width: 100%;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
}

.vacant-item .button {
  position: relative;
  width: 150px;
  justify-content: center;
  display: flex;
}

@media all AND (max-width: 900px) {
  .vacant-item {
    align-items: center;
  }
  .vacant-item .container {
    flex: 1 0 100%;
  }
}
