@import "../../../styles/colors";

.entity {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 8px;
  position: relative;
  width: calc(100% - 5px);
  min-height: 170px;

  .avatar-line {
    align-items: center;
    display: flex;
    flex: 0 0 auto;
    gap: 24px;
    margin-right: -24px;
    position: relative;
    width: 100%;

    .text-wrapper-name {
      color: $gray-700;
      font-family: "Lato", Helvetica;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      letter-spacing: 0px;
      line-height: 150%;
      position: relative;
      white-space: nowrap;
      width: fit-content;
    }

    .text-wrapper-desc {
      color: var(--graygray-700);
      font-family: "Lato", Helvetica;
      font-size: 16px;
      font-weight: 400;
      letter-spacing: 0;
      line-height: 24px;
      position: relative;
      white-space: nowrap;
      width: fit-content;
      min-height: 25px;
      display: flex;
      flex-direction: row;
      gap: 8px;
    }

    .person-icon {
      align-items: flex-start !important;
      flex: 0 0 auto !important;
      display: inline-flex;
      flex-direction: column;
      gap: 16px;
      position: relative;

      .avatar-icon {
        height: 64px;
        object-fit: cover;
        position: relative;
        width: 64px;
        background-color: $black;
        border-radius: 35px;
      }
      .avatar-text {
        align-items: flex-start;
        display: flex;
        flex: 1;
        flex-grow: 1;
        flex-direction: column;
        flex-grow: 1;
        gap: 4px;
        position: relative;
      }
    }
  }

  .content {
    align-self: stretch !important;
    display: flex !important;
    flex: 0 0 auto !important;
    width: 100% !important;
    flex-direction: column;
    gap: 8px;
    align-items: flex-start;
    display: inline-flex;
    position: relative;

    a {
      width: 100%;
    }

    p {
      align-self: stretch !important;
      width: unset !important;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      backdrop-filter: blur(40px) brightness(100%);
    }

    .button {
      all: unset;
      align-items: center;
      align-self: stretch;
      box-sizing: border-box;
      display: flex;
      flex: 0 0 auto;
      gap: 8px;
      justify-content: center;
      padding: 4px 4px;
      position: relative;
      width: 100%;

      background-color: $boxBackground;
      border: 1px $black;
      box-shadow: 0px 4px 4px $boxShadow;

      .placeholder {
        margin-top: -1px;
        position: relative;
        white-space: nowrap;
        width: fit-content;
      }
    }
  }
}
